<template>
  <div>
    <page-loading :show="loading" />
    <form @submit.prevent="submitRequest">
      <my-alert
        :variant="messageType"
        :show="messageShow"
        :dismissible="true"
        @dismissed="closeAlert"
      >
        <div v-html="messageAlert"></div>
      </my-alert>
      <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
        <div class="left--col">
          <label for="email">
            <span>{{ $t('home.corporateClient.email') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            name="email"
            id="email"
            type="email"
            :placeholder="$t('home.managerSection.placeholder.emails')"
            v-model="emailInput"
          />
          <span class="val-error" v-if="validation.hasError('email')">{{
            validation.firstError('email')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('name') }">
        <div class="left--col">
          <label for="name">
            <span>{{ $t('home.corporateClient.name') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            id="name"
            name="name"
            type="text"
            :placeholder="$t('home.managerSection.placeholder.company')"
            v-model="name"
          />
          <span class="val-error" v-if="validation.hasError('name')">{{
            validation.firstError('name')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('type') }">
        <div class="left--col">
          <label for="type">
            <span>{{ $t('home.corporateClient.type') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="type"
            name="type"
            v-model="type"
            select-label=""
            deselect-label=""
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :options="types"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('type')">{{
            validation.firstError('type')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('industry') }">
        <div class="left--col">
          <label for="industry">
            <span>{{ $t('home.corporateClient.industry') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            id="industry"
            name="industry"
            type="text"
            :placeholder="$t('home.managerSection.placeholder.type')"
            v-model="industry"
          />
          <span class="val-error" v-if="validation.hasError('industry')">{{
            validation.firstError('industry')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('profile') }">
        <div class="left--col">
          <label for="profile">
            <span>{{ $t('home.corporateClient.profile') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <textarea
            class="basic--input"
            id="profile"
            name="profile"
            v-model="profile"
            rows="5"
            :placeholder="$t('home.managerSection.placeholder.profile')"
          ></textarea>
          <span class="val-error" v-if="validation.hasError('profile')">{{
            validation.firstError('profile')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('profile_file') }">
        <div class="left--col">
          <label for="profile-file">
            <span>{{ $t('home.corporateClient.profileFile') }}</span>
          </label>
        </div>
        <div class="right--col">
          <button
            class="btn btn-primary--outline btn--upload-profile"
            @click="onPickFile"
            type="button"
          >
            Upload Company Profile
          </button>
          <div class="d-flex align-center" v-if="profile_file">
            <div class="col">Uploaded file: {{ profile_file.name }}</div>
            <img
              src="@/assets/img/icons/trash.svg"
              @click="removeFile"
              class="trash--button col-auto"
            />
          </div>
          <input
            id="profile-file"
            type="file"
            @change="onFileChange"
            accept=".jpg, .png, .jpeg, .pdf"
            ref="profile_file_ref"
            style="display: none"
          />
          <span class="val-error" v-if="validation.hasError('profile_file')">{{
            validation.firstError('profile_file')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('address') }">
        <div class="left--col">
          <label for="address">
            <span>{{ $t('home.corporateClient.address') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <textarea
            class="basic--input"
            id="address"
            name="address"
            v-model="address"
            rows="5"
            :placeholder="$t('home.managerSection.placeholder.address')"
          ></textarea>
          <span class="val-error" v-if="validation.hasError('address')">{{
            validation.firstError('address')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('website') }">
        <div class="left--col">
          <label for="website">
            <span>{{ $t('home.corporateClient.website') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            id="website"
            name="website"
            type="text"
            :placeholder="$t('home.managerSection.placeholder.website')"
            v-model="website"
          />
          <span class="val-error" v-if="validation.hasError('website')">{{
            validation.firstError('website')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('property_type') }">
        <div class="left--col">
          <label for="property-type">
            <span>{{ $t('home.corporateClient.propertyType') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="property-type"
            name="property-type"
            v-model="property_type"
            select-label=""
            deselect-label=""
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :options="property_types"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('property_type')">{{
            validation.firstError('property_type')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('estimate_pax') }">
        <div class="left--col">
          <label for="estimate-pax">
            <span>{{ $t('home.corporateClient.estimatePax') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="estimate-pax"
            name="estimate-pax"
            v-model="estimate_pax"
            select-label=""
            deselect-label=""
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :options="estimate_pax_list"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('estimate_pax')">{{
            validation.firstError('estimate_pax')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('size') }">
        <div class="left--col">
          <label for="size">
            <span>{{ $t('home.corporateClient.size') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            id="size"
            name="size"
            type="text"
            :placeholder="$t('home.managerSection.placeholder.size')"
            v-model="size"
          />
          <span class="val-error" v-if="validation.hasError('size')">{{
            validation.firstError('size')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('monthly_budget') }">
        <div class="left--col">
          <label for="monthly-budget">
            <span>{{ $t('home.corporateClient.monthlyBudget') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="monthly-budget"
            name="monthly-budget"
            v-model="monthly_budget"
            select-label=""
            deselect-label=""
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :options="monthly_budget_list"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('monthly_budget')">{{
            validation.firstError('monthly_budget')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('yearly_budget') }">
        <div class="left--col">
          <label for="yearly-budget">
            <span>{{ $t('home.corporateClient.yearlyBudget') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <multiselect
            id="yearly-budget"
            name="yearly-budget"
            v-model="yearly_budget"
            select-label=""
            deselect-label=""
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :options="yearly_budget_list"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('yearly_budget')">{{
            validation.firstError('yearly_budget')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('contact') }">
        <div class="left--col">
          <label for="contact">
            <span>{{ $t('home.corporateClient.contact') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            id="contact"
            name="contact"
            type="text"
            :placeholder="$t('home.managerSection.placeholder.contact')"
            v-model="contact"
          />
          <span class="val-error" v-if="validation.hasError('contact')">{{
            validation.firstError('contact')
          }}</span>
        </div>
      </div>
      <div class="d-flex justify-center">
        <button type="submit" class="btn btn-primary">{{ $t('home.coRenting.send_form') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const PageLoading = () => import('@/components/content-loading/page-loading');

import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
// import VueRecaptcha from 'vue-recaptcha';
import { Validator } from 'simple-vue-validator';
import MyAlert from '@/components/utils/my-alert.vue';
export default {
  name: 'CorporateClientForm',
  mixins: [HelperMixin],
  components: {
    MyAlert,
    Multiselect,
    PageLoading,
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      messageAlert: (state) => state.global.message,
      messageType: (state) => state.global.messageType,
      property_types: (state) => state.v2.masters.propertyTypes,
      listingType: (state) => state.v2.search.listingType,
    }),
    emailInput: {
      get() {
        return this.email;
      },
      set(val) {
        this.email = this.cleanEmail(val);
      },
    },
  },
  data: () => ({
    loading: false,
    email: '',
    name: '',
    type: '',
    industry: '',
    profile: '',
    profile_file: '',
    address: '',
    website: '',
    property_type: '',
    estimate_pax: '',
    size: '',
    monthly_budget: '',
    yearly_budget: '',
    contact: '',
    messageShow: false,
    types: [
      {
        id: 1,
        name: 'PT',
      },
      {
        id: 2,
        name: 'PT Tbk',
      },
      {
        id: 3,
        name: 'PT Persero',
      },
      {
        id: 4,
        name: 'PT Persero Tbk',
      },
      {
        id: 5,
        name: 'Yayasan',
      },
      {
        id: 6,
        name: 'CV',
      },
      {
        id: 7,
        name: 'Perum',
      },
      {
        id: 8,
        name: 'Lainnya',
      },
    ],
    estimate_pax_list: [
      {
        id: 1,
        name: '< 10 pax',
      },
      {
        id: 2,
        name: '10 - 50 pax',
      },
      {
        id: 3,
        name: '50 - 100 pax',
      },
      {
        id: 4,
        name: '> 100 pax',
      },
    ],
    monthly_budget_list: [
      {
        id: 1,
        name: '< Rp 10.000.000,00',
      },
      {
        id: 2,
        name: 'Rp 10.000.000,00 - Rp 25.000.000,00',
      },
      {
        id: 3,
        name: 'Rp 25.000.000,00 - Rp 50.000.000,00',
      },
      {
        id: 4,
        name: 'Rp 50.000.000,00 - Rp 100.000.000,00',
      },
      {
        id: 5,
        name: '> Rp 100.000.000,00',
      },
    ],
    yearly_budget_list: [
      {
        id: 1,
        name: '< Rp 50.000.000,00',
      },
      {
        id: 2,
        name: 'Rp 50.000.000,00 - Rp 100.000.000,00',
      },
      {
        id: 3,
        name: 'Rp 100.000.000,00 - Rp 500.000.000,00',
      },
      {
        id: 4,
        name: 'Rp 500.000.000,00 - Rp 1.000.000.000,00',
      },
      {
        id: 5,
        name: '> Rp 1.000.000.000,00',
      },
    ],
  }),
  validators: {
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.email.max', { maxChar: 255 }));
    },
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.name.required'));
    },
    type(value) {
      return Validator.value(value).required(this.$i18n.t('errors.companyType.required'));
    },
    industry(value) {
      return Validator.value(value).required(this.$i18n.t('errors.industryType.required'));
    },
    profile(value) {
      return Validator.value(value).required(this.$i18n.t('errors.companyProfile.required'));
    },
    address(value) {
      return Validator.value(value).required(this.$i18n.t('errors.address.required'));
    },
    website(value) {
      return Validator.value(value).required(this.$i18n.t('errors.companyWebsite.required'));
    },
    property_type(value) {
      return Validator.value(value).required(this.$i18n.t('errors.companyPropType.required'));
    },
    estimate_pax(value) {
      return Validator.value(value).required(this.$i18n.t('errors.estimatedPax.required'));
    },
    size(value) {
      return Validator.value(value).required(this.$i18n.t('errors.estimatedSize.required'));
    },
    monthly_budget(value) {
      return Validator.value(value).required(this.$i18n.t('errors.monthlyBudget.required'));
    },
    yearly_budget(value) {
      return Validator.value(value).required(this.$i18n.t('errors.yearlyBudget.required'));
    },
    contact(value) {
      return Validator.value(value).required(this.$i18n.t('errors.contactPerson.required'));
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.$store.dispatch('v2/masters/getPropertyTypes', { listingType: this.listingType });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    onPickFile() {
      this.$refs.profile_file_ref.click();
    },
    removeFile() {
      this.profile_file = null;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.profile_file = files[0];
    },
    async submitRequest() {
      try {
        this.loading = true;

        const isValid = await this.$validate();
        if (isValid) {
          let formData = new FormData();
          formData.append('email', this.email);
          formData.append('name', this.name);
          formData.append('type', this.type ? this.type.name : null);
          formData.append('industry', this.industry);
          formData.append('profile', this.profile);
          formData.append('profile_file', this.profile_file);
          formData.append('address', this.address);
          formData.append('website', this.website);
          formData.append('property_type_id', this.property_type ? this.property_type.id : null);
          formData.append('estimate_pax', this.estimate_pax ? this.estimate_pax.name : null);
          formData.append('size', this.size);
          formData.append('monthly_budget', this.monthly_budget ? this.monthly_budget.name : null);
          formData.append('yearly_budget', this.yearly_budget ? this.yearly_budget.name : null);
          formData.append('contact', this.contact);
          try {
            let response = await this.$store.dispatch('global/corporateClient', formData);
            if (response && response.type === 'success') {
              await this.$swal(
                this.$t('general.success'),
                'Terima Kasih telah menghubungi kami. Kami akan segera menghubungi Anda kembali.',
                'success',
              );
              this.resetForm();
            }
          } catch (err) {
            console.log('Error request corporate client: ', err);
          }
        } else {
          this.scrollToErrorSection(this.$el);
        }
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading = false;
      }
    },
    closeAlert() {
      this.$store.commit('global/RESET_ALERT');
    },
    resetForm() {
      this.email = null;
      this.name = null;
      this.type = null;
      this.industry = null;
      this.profile = null;
      this.profile_file = null;
      this.address = null;
      this.website = null;
      this.property_type = null;
      this.estimate_pax = null;
      this.size = null;
      this.monthly_budget = null;
      this.yearly_budget = null;
      this.contact = null;
      this.validation.reset();
    },
  },
};
</script>
