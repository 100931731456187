<template>
  <section class="help--desk-wrapper">
    <div class="title--text">
      {{ $t('home.corporateClient.title') }}
    </div>
    <div class="alert alert-warning" role="alert">
      Mencari properti sesuai dengan kebutuhan perusahaan anda kini menjadi lebih mudah, aman, dan
      cepat. Rentfix hadir untuk memberikan jasa pencarian properti terbaik. Daftar untuk mencari
      properti yang perusahaan Anda butuhkan.
    </div>
    <corporate-client-form />
  </section>
</template>

<script>
import CorporateClientForm from '@/components/help-desk/corporate-client/corporate-client-form';

export default {
  components: {
    CorporateClientForm,
  },
};
</script>
